import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MANSIDE_ROUTE, WOMANSIDE_ROUTE } from "./utils/constants";
import ManSidePage from "./pages/ManSidePage/ManSidePage";
import WomanSidePage from "./pages/WomanSidePage/WomanSidePage";
import MainPage from "./pages/MainPage/MainPage";
import AppRouter from "./components/AppRouter/AppRouter";
import CoursePage from "./pages/CoursePage/CoursePage";

import {
  analArr,
  AquaExArr,
  AquaProArr,
  dominantArr,
  KissArr,
  KyniArr,
  manCourses,
  PotencialArr,
  pozyArr,
} from "./utils/arraysMan";
import { aquaGArr, deepArr, lingamArr, tiloKArr, womanCourses } from "./utils/arraysWoman";
import AllCoursesPage from "./pages/AllCoursesPage/AllCoursesPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppRouter />}>
          <Route index element={<MainPage />} />
          <Route path={MANSIDE_ROUTE} element={<ManSidePage />} />
          <Route path={WOMANSIDE_ROUTE} element={<WomanSidePage />} />
          <Route
            path={"/man-side/aquapark-pro-max-vip"}
            element={
              <CoursePage
                urlCover={manCourses[3].urlCover}
                aboutUrl={manCourses[3].imageSrc}
                aboutTitle={"Аквапарк Pro Max VIP"}
                aboutTextFirst={
                  "ТРАХАЙ ТА ЗАРОБЛЯЙ Тут лише профі! Знання з курсу допоможуть тобі не лише задовільняти свою дівчину на найвищому рівні, а навіть потрапити в індустрію 18+. Якщо ти готовий до того, що твої руки, член та пальці будуть пікантною темою для розмов у колі жінок - тоді я чекамо тебе в банді."
                }
                courseName={"Аквапарк Pro Max VIP"}
                isManSide={true}
                courseArr={AquaProArr}
                willLernArr={manCourses[3].willLernArr}
                />
            }
          />
          <Route
            path={"/man-side/yoni-master"}
            element={
              <CoursePage
                urlCover={manCourses[1].urlCover}
                aboutUrl={manCourses[1].imageSrc}
                aboutTitle={"Йоні Майстер"}
                aboutTextFirst={
                  "Твоя дівчина нарешті дізнається, що таке сквірт! Так — це не міф: тепер відчути це зможе кожна. Усі уроки записані на дівчині, яка ніколи не сквіртувала — її живі емоції та усі техніки крупним планом дають можливість зануритись глибоко у тему та дізнатись усі тонкощі того самого “неуловимого” . Ми навчимо тебе — як творити оргазм найперше в її голові. Але одразу попередження: твої нові вміння стануть темою для обговорення в колі її подружок. Готовий? GO"
                }
                courseName={"Йоні Майстер"}
                courseArr={AquaExArr}
                isManSide={true}
                willLernArr={manCourses[1].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/dominant"}
            element={
              <CoursePage
                urlCover={manCourses[4].urlCover}
                aboutUrl={manCourses[4].imageSrc}
                aboutTitle={"Домінант Pro Max"}
                aboutTextFirst={
                  "Курс який відриє тобі БДСМ світ. Домінація — це те, що виведе твій секс та життя на топ рівень. Тут ти побачиш контент, який заслуговує на перше місце у топі PORN HUB, тому запасайся серветками і вивчай."
                }
                courseName={"Домінант Pro Max"}
                courseArr={dominantArr}
                isManSide={true}
                willLernArr={manCourses[4].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/kyni-do-skvirta"}
            element={
              <CoursePage
                urlCover={manCourses[2].urlCover}
                aboutUrl={manCourses[2].imageSrc}
                aboutTitle={"Куні до Сквірту"}
                aboutTextFirst={
                  "ДОСИТЬ ЯЛОЗИТИ ЯЗИКОМ ЯК ВЕСЛОМ, вже сьогодні вона буде кінчати і сквіртувати від твого КУНІКА. Гарна новина для бішечок та лесбійок: тут ви знайдете топові уроки які підійдуть як для чоловіків, так і для дівчат! Після навчання ти більше не почуєш “сквірт — це щось на фантастичному”. Починай ламати ці міфи — твій язик здатний на все."
                }
                courseName={"Куні до Сквірту"}
                courseArr={KyniArr}
                isManSide={true}
                willLernArr={manCourses[2].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/anal-do-skvirta"}
            element={
              <CoursePage
                urlCover={manCourses[6].urlCover}
                aboutUrl={manCourses[6].imageSrc}
                aboutTitle={"Анал до сквірта"}
                aboutTextFirst={
                  "Відкрий новий вхід у світ насолоди. Чому цей курс у розділі для пар? Бо сквіртовий анал вимагає знань від двох. Тому ми зібрали найефективнішу підготовку до АНАЛЬНОГО СЕКСУ для дівчини і хлопця. Дівчата, готуй не лише свою жопу, а й мозок — тут ми пропрацює усі тригери, розтиснемо булочки та душу. А ви пацики готуйте член та мізки — тут потрібні вони обидва. READY?"
                }
                courseName={"Анал до сквірта"}
                courseArr={analArr}
                isManSide={true}
                willLernArr={manCourses[6].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/kiss"}
            element={
              <CoursePage
                urlCover={manCourses[7].urlCover}
                aboutUrl={manCourses[7].imageSrc}
                aboutTitle={"Цілуй"}
                aboutTextFirst={
                  "Поцілунок — це те, з чого усе починається. І в кожного є та сама крінж історія поцілунку, яку твій мозок намагається забути. “Та що там його цілуватися” - фатальна помилка. Поцілунок — інтимніше навіть сексу. Саме він може або подарувати тобі найкращі стосунки твого життя або зруйнувати все ще на самому початку. Час вивести свої цілувальні здібрості на рівень ПРОФІ. Починай вивчати вже зараз"
                }
                courseName={"Цілуй"}
                courseArr={KissArr}
                isManSide={true}
                willLernArr={manCourses[7].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/pozy-18"}
            element={
              <CoursePage
                urlCover={manCourses[5].urlCover}
                aboutUrl={manCourses[5].imageSrc}
                aboutTitle={"Пози 18+"}
                aboutTextFirst={
                  "Такого формату сексу в тебе ще не було! Пози, які удосконалені та протестовані десятками пар. Такій деталізації позаздрить навіть Камасутра, адже цей курс — інструкція оновлення вашого інтиму до версії РRO. Навіть звичні пози ми перетворимо на витвори оргазмового мистецтва. Попереджаємо одразу — сусіди вас зненавидять і їх нічого не врятує від перевищення рівня децибелів."
                }
                courseName={"Пози 18+"}
                courseArr={pozyArr}
                isManSide={true}
                willLernArr={manCourses[5].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/potencial-chlena"}
            element={
              <CoursePage
                urlCover={manCourses[0].urlCover}
                aboutUrl={manCourses[0].imageSrc}
                aboutTitle={"Потенціал Члена 2.0"}
                aboutTextFirst={
                  "Потенціал - це те, що можна і треба розвивати. На курсі ти дізнаєшся, як повністю прокачати своє тіло та вивести свою чоловічу силу до рівня «термінатор»! Твій член стоятиме усю ніч, а трахати будеш не гірше порно акторів. Чітко виконуючи усі завдання курсу, ти забудеш, що таке швидкий фініш чи проблеми з падіннями. Разом зі спікерами розкриємо усі психологічні та фізичні чинники, які впливають на твій секс і загальне самопочуття. Вивчай, аби бути на поготові завджи і всюди, та фінішувати як вперше до глибокої старості."
                }
                courseName={"Потенціал Члена 2.0"}
                courseArr={PotencialArr}
                isManSide={true}
                willLernArr={manCourses[0].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/deep-soul"}
            element={
              <CoursePage
                urlCover={womanCourses[0].urlCover}
                aboutUrl={womanCourses[0].imageSrc}
                aboutTitle={"Глибока Душа"}
                aboutTextFirst={
                  "Новий формат твого мінету. Слюнявий — це не просто техніки і практики, як довести чоловіка до оргазму. Ні! Тут ти навчишся пізнавати себе через чоловіка. Адже сосати добре може лише та, кому по справжньому це подобається. Усе починається з голови, а тільки потім продовжується у глотці. Тому, якщо ти готова почати кайфувати від себе та підкріплювати цей ефект його стогоном задоволення — тоді welcome to the club."
                }
                courseArr={deepArr}
                courseName={"Глибока Душа"}
                isManSide={false}
                willLernArr={womanCourses[0].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/aquapark-girls"}
            element={
              <CoursePage
                urlCover={womanCourses[1].urlCover}
                aboutUrl={womanCourses[1].imageSrc}
                aboutTitle={"Аквапарк Girls"}
                aboutTextFirst={
                  "Дізнайся на що спроможні твої ручки. Підкорити чоловіка повністю — ось якою силою ви  оволодієте. Техніки, які  не знайдеш у звичайних “масажках” - створені головним майстром еро технік в Україні: Leon13. Тільки попереджаємо одразу — у нього може з’явитись залежність від твоїх масажів. Якщо готова — тоді гоу)"
                }
                courseName={"Аквапарк Girls"}
                courseArr={aquaGArr}
                isManSide={false}
                willLernArr={womanCourses[1].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/tilo-kinchay"}
            element={
              <CoursePage
                urlCover={womanCourses[2].urlCover}
                aboutUrl={womanCourses[2].imageSrc}
                aboutTitle={"Тіло Кінчай"}
                aboutTextFirst={
                  "Тут ти навчиш СВОЄ ТІЛО - КІНЧАТИ! Цей курс - інструкція до ТЕБЕ, з якою ти розкриєш усі свої потаємні можливості та бажання."
                }
                courseName={"Тіло Кінчай"}
                courseArr={tiloKArr}
                isManSide={false}
                willLernArr={womanCourses[2].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/pozy18"}
            element={
              <CoursePage
                urlCover={womanCourses[3].urlCover}
                aboutUrl={womanCourses[3].imageSrc}
                aboutTitle={"Пози 18+"}
                aboutTextFirst={
                  "Такого формату сексу в тебе ще не було! Пози, які удосконалені та протестовані десятками пар. Такій деталізації позаздрить навіть Камасутра, адже цей курс — інструкція оновлення вашого інтиму до версії РRO. Навіть звичні пози ми перетворимо на витвори оргазмового мистецтва. Попереджаємо одразу — сусіди вас зненавидять і їх нічого не врятує від перевищення рівня децибелів."
                }
                courseArr={pozyArr}
                courseName={"Пози 18+"}
                isManSide={false}
                willLernArr={womanCourses[3].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/anal-do-skvirta"}
            element={
              <CoursePage
                urlCover={womanCourses[4].urlCover}
                aboutUrl={womanCourses[4].imageSrc}
                aboutTitle={"Анал до сквірта"}
                aboutTextFirst={
                  "Відкрий новий вхід у світ насолоди. Чому цей курс у розділі для пар? Бо сквіртовий анал вимагає знань від двох. Тому ми зібрали найефективнішу підготовку до АНАЛЬНОГО СЕКСУ для дівчини і хлопця. Дівчата, готуй не лише свою жопу, а й мозок — тут ми пропрацює усі тригери, розтиснемо булочки та душу. А ви пацики готуйте член та мізки — тут потрібні вони обидва. READY?"
                }
                courseArr={analArr}
                courseName={"Анал до сквірта"}
                isManSide={false}
                willLernArr={womanCourses[4].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/lingam-prostata"}
            element={
              <CoursePage
                urlCover={womanCourses[5].urlCover}
                aboutUrl={womanCourses[5].imageSrc}
                aboutTitle={"Лінгам-Простата"}
                aboutTextFirst={
                  "Він кінчить за секунди! Ти станеш тією, хто відведе чоловіка у світ насолоди і підкорить тіло, діставши до душі. Тут ти вчитимешся справжньому мистецтву заволодіння самим єством лише з допомогою своїх рук. Якщо ти готова стати його залежністю, то погнали."
                }
                courseName={"Лінгам-Простата"}
                courseArr={lingamArr}
                isManSide={false}
                willLernArr={womanCourses[5].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/kiss"}
            element={
              <CoursePage
                urlCover={womanCourses[6].urlCover}
                aboutUrl={womanCourses[6].imageSrc}
                aboutTitle={"Цілуй"}
                aboutTextFirst={
                  "Поцілунок — це те, з чого усе починається. І в кожного є та сама крінж історія поцілунку, яку твій мозок намагається забути. “Та що там його цілуватися” - фатальна помилка. Поцілунок — інтимніше навіть сексу. Саме він може або подарувати тобі найкращі стосунки твого життя або зруйнувати все ще на самому початку. Час вивести свої цілувальні здібрості на рівень ПРОФІ. Починай вивчати вже зараз"
                }
                courseName={"Цілуй"}
                courseArr={KissArr}
                isManSide={true}
                willLernArr={womanCourses[6].willLernArr}
              />
            }
          />
          <Route path={"all-courses"} element={<AllCoursesPage />}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
